"use client";

import ProductCard from "@/features/product/components/ProductCard";
import ProductGrid from "@/features/product/components/ProductGrid";
import useIntersection from "@/hooks/useIntersection";

import type {
  PLPContent,
  PlpProductsSectionProps
} from "@/lib/centra/hooks/usePlp";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import clsx from "clsx";
import { Fragment, useCallback, useState } from "react";
import styles from "./index.module.css";

const ProductsSection = ({
  data,
  gridSize
}: {
  data: PlpProductsSectionProps;
  gridSize: number;
}) => {
  const { plpContent, productCount, fetchNextPage, hasNextPage, isFetching } =
    data;

  const products = plpContent.reduce(
    (acc: PLPContent[number]["products"], curr) => {
      return [...acc, ...curr.products];
    },
    []
  );
  return (
    <>
      <section>
        <ProductGrid
          id="grid"
          className={clsx(gridSize === 3 ? styles.grid3 : styles.grid4)}
        >
          {plpContent?.map((section, i) => (
            <Fragment key={i}>
              {section?.products?.map((product, index) => {
                return (
                  <ProductCard
                    product={product}
                    key={`product-${product?.id}-${product?.core?.slug}`}
                    priority={index < 4}
                  />
                );
              })}
              <ProductEditorial section={section} gridSize={gridSize} />
            </Fragment>
          ))}
        </ProductGrid>
      </section>
      <div className={styles.paginator}>
        {hasNextPage && <LoadMore fetchNextPage={fetchNextPage} />}
        <p className={styles.description}>
          Showing {products.length} of {productCount}
        </p>
      </div>
    </>
  );
};

export default ProductsSection;

const LoadMore = ({
  fetchNextPage
}: {
  fetchNextPage: () => Promise<unknown>;
}) => {
  const [loadMore, setLoadMore] = useState(false);

  return (
    <>
      {!loadMore ? (
        <button
          className={styles.loadMore}
          onClick={async () => {
            setLoadMore(true);
          }}
        >
          Load more products
        </button>
      ) : (
        <Sentinel fetchNextPage={fetchNextPage} />
      )}
    </>
  );
};

const Sentinel = ({
  fetchNextPage
}: {
  fetchNextPage: () => Promise<unknown>;
}) => {
  const handleInView = useCallback(
    (entries: IntersectionObserverEntry[], _observer: IntersectionObserver) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage]
  );

  const { Ref } = useIntersection(handleInView);

  return (
    <span className={styles.sentinel} aria-hidden id="sentinel" ref={Ref} />
  );
};

const ProductEditorial = ({ section, gridSize }: any) => {
  return (
    <>
      {section?.editorial?.map(
        (editorialBlok: any, key: number) =>
          key * 2 < section?.products.length / gridSize && (
            <div
              className={clsx(
                styles.editorial,
                gridSize === 3 ? styles.sectionGrid3 : styles.sectionGrid4
              )}
              key={key}
              style={{
                gridRow: 3 * key + 2
              }}
            >
              <StoryblokComponent blok={editorialBlok} />
            </div>
          )
      )}
    </>
  );
};
